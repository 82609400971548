// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Matthias",
  middleName: "Peter",
  lastName: "Feller",
  message: " Passioné d'informatique, prêt à faire évoluer le monde. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/Lenfey/",
    },
    {
      image: "fa-facebook",
      url: "https://www.facebook.com/Genoxx/",
    },
    {
      image: "fa-instagram",
      url: "https://www.instagram.com/meybeam/",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/matthiasfellerch/",
    },
    //{
      //image: "fa-twitter",
      //url: "https://www.twitter.com/hashirshoaeb/",
    //},
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "A propos de moi",
  imageLink: require("../editable-stuff/1697122740928.jpg"),
  imageSize: 375,
  message:
    "Mon nom est Matthias Feller. J'ai étudié à l'HES-SO de Genève, ou j'ai reçu mon Bachelor d'ingénieur en technologie de l'information, spécialisation télécommunication, multimédia et réseau en 2016. Je suis un passionné de l'informatique en général, et mon but est d'apprendre de nouvelles choses tous les jours. Dans mon temps libre, je m'adonne à des veilles technologiques, ou à mes passions telles que les jeux vidéo, ou la formule 1.",
  resume: require("../editable-stuff/resume.pdf"),
};

// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: false,
  heading: "Recent Projects",
  gitHubUsername: "lenfey", //i.e."johnDoe12Gh"
  reposLength: 0,
  specificRepos: [],
};

// Leadership SECTION
 const leadership = {
   show: false,
//   heading: "Certifications",
//   message:
//     "Dans ma quête de formation et d'évolution, je suis amené à obtenir des certifications. Je me spécialise dans le domaine cloud, et plus précisément dans le cloud Microsoft Azure. J'évolue dans le but de maintenir, gérer et évoluer des solutions cloud, tout en faisant attention de ne pas négliger les solutions on-premises, pour les entreprises qui ont des besoins spécifiques.",
//   images: [
//     { 
//       img: require("../assets/img/AZ104.png"), 
//       label: "AZ-104 - 2024", 
//       paragraph: "Microsoft Certified: Azure Administrator Associate" 
//     },
//     { 
//       img: require("../assets/img/dell.png"), 
//       label: "SE: Core Client Credential 2020", 
//       paragraph: "Certificate knowledge for Dell workstations" 
//     },
//     { 
//       img: require("../assets/img/dell.png"), 
//       label: "SE: Server Credential 2020", 
//       paragraph: "Certificate knowledge for Dell servers" 
//     },
//   ],
//   imageSize: {
//     width:"615",
//     height:"450"
//   }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Compétences",
  hardSkills: [
    { name: "Administration Réseau", value: 75 },
    { name: "Gestion infrastructure", value: 75 },
    { name: "VMWare ESX/vCenter", value: 75 },
    { name: "Hyperconvergence", value: 65 },
    { name: "Windows", value: 95 },
    { name: "Linux", value: 50 },
    { name: "Active Directory", value: 100 },
    { name: "Microsoft Azure", value: 85 },
    { name: "Microsoft Office 365", value: 100 },
    { name: "Windows Powershell", value: 70 },
    { name: "Bash/Python scripting", value: 65 },
    { name: "Docker", value: 50 },
  ],
  softSkills: [
    { name: "Axé sur objectif", value: 80 },
    { name: "Gestion de projet", value: 70 },
    { name: "Collaboration", value: 90 },
    { name: "Positivité", value: 100 },
    { name: "Adaptation", value: 85 },
    { name: "Résolution d'incidents", value: 75 },
    { name: "Empathie", value: 90 },
    { name: "Organisation", value: 70 },
    { name: "Anglais", value: 95 },
    { name: "Français", value: 100 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Me contacter",
  message:
    "Si vous cherchez un Ingénieur système et réseau dans le canton de Genève, merci de me contacter via LinkedIn ou par mail à l'adresse suivante",
  email: "matthiasfellerch@gmail.com",
};

const experiences = {
  show: true,
  heading: "Expériences",
  data: [
    {
      role: 'Administrateur système et réseau',// Here Add Company Name
      companylogo: require('../assets/img/silicom.png'),
      date: 'Avril 2018 – Present',
    },
    
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences };
